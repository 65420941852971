import React from "react";
import "./Projects.css";
import LineHeader from "../../components/LineHeader";
import ProjectList from "./ProjectList";
import projects  from "../../projects.json";

const projectsList = projects.projects;

const Projects = () => {
  return (
    <section className="section__padding projects" id="projects">
      <h2>Latest Projects</h2>
      <LineHeader />
      <div className="container">
        <div className="projects-gallery scroll-bottom">
          {projectsList.length > 0 &&
            projectsList.map((project, index) => (
              <ProjectList
                key={index}
                src={project.src}
                alt={project.alt}
                title={project.title}
                description={project.description}
                href={project.href}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";


const ProjectList = ({ src, alt, title, description, href }) => {

  return (
    <div className={"port-box"}>
      <div className="port-image">
        <img src={src} alt={alt} />
      </div>
      <div className="port-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <a href={href} target="__blank">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </div>
  );
};

export default ProjectList